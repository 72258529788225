<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                        <p v-html="$t('digital.delivery_form.description')"></p>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" @success="formSuccess">
                        <div class="popup-body popup-rows">

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_form.order') }}</label></div>
                                <div class="column">
                                    <g-input name="order_reference" label="Bestelling" disabled/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_form.sender') }}</label></div>
                                <div class="column">
                                    <g-input name="sender_name" :label="$t('digital.delivery_form.sender_name')" max="76"/>
                                    <g-input name="sender_email" :label="$t('digital.delivery_form.sender_email')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_form.subject') }}</label></div>
                                <div class="column">
                                    <g-input name="subject" :label="$t('digital.delivery_form.subject_label')" max="76"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_form.message') }}</label></div>
                                <div class="column">
                                    <g-input :label="$t('digital.delivery_form.message_label')"
                                             name="message" type="textarea"/>
                                    <p class="help">{{ $t('digital.delivery_form.message_help') }}</p>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_form.addresses') }}</label></div>
                                <div class="column">
                                    <g-input :label="$t('digital.delivery_form.addresses_label')" name="recipients" type="textarea"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"></div>
                                <div class="column">
                                    <g-switch name="accept_conditions" :label="$t('digital.delivery_form.agree')"
                                              :subtitle="formConditionsText"/>
                                </div>
                            </div>
                        </div>

                        <div class="popup-body">
                            <div class="notification is-warning">
                                <div>
                                    {{ $t('digital.delivery_form.email_check', {
                                        email: this.$store.getters['user/email']
                                    }) }}
                                </div>
                                <button @click.prevent="sendTestMail" class="button is-white"
                                        :class="{ 'is-loading' : this.testLoading }">
                                    {{ $t('digital.delivery_form.send') }}
                                </button>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button :disabled="formDisabled">{{ $t('digital.delivery_form.submit') }}</g-button>
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('digital.delivery_form.cancel') }}</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: String
    },

    data () {
        const order         = this.$store.getters['promotionalOrders/getById'](this.id)
        const companyEmail  = this.$store.getters['user/activeCompany'].name ?? null
        const employeeEmail = this.$store.getters['user/user'].email ?? null

        const formData = {
            'order_reference':   this.$t('digital.delivery_form.order_reference', {
                                     quantity: order.quantity,
                                     price: this.$options.filters.currency(order.amount / 100),
                                     reference: order.reference
                                 }),
            'recipients':        null,
            'sender_name':       companyEmail,
            'sender_email':      employeeEmail,
            'subject':           null,
            'message':           null,
            'accept_conditions': false,
        }

        return {
            order:       order,
            testLoading: false,
            form:        new Form(formData),
        }
    },

    computed: {
        formTitle () {
            return this.$t('digital.delivery_form.title')
        },
        formConditionsText () {
            return this.$t('digital.delivery_form.conditions')
        },
        formEndpoint () {
            return `/api/company/${this.$store.getters['user/activeCompanyId']}/promotional/${this.id}/delivery`
        },
        formMethod () {
            return 'post'
        },
        formDisabled () {
            return !this.form.accept_conditions
        },
    },

    methods: {
        formSuccess (response) {
            const action = 'promotionalOrders/loadById'

            this.$store
                .dispatch(action, {
                    order_id: this.id,
                })
                .then(this.destroy)
        },
        sendTestMail () {
            this.testLoading = true

            this.form.post(this.formEndpoint + 'Test')
                .then(() => {
                    this.$buefy.toast.open({
                        message: this.$t('digital.delivery_form.test_mail_sent'),
                        type:    'is-success'
                    })
                })
                .finally(() => {
                    this.testLoading = false
                })
        },
    },
}
</script>
