<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>Campagne</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" label="Naam"/>
                                    </div>
                                    <small>Deze naam is publiekelijk inzichtelijk.</small>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>Partner</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-select name="partner_id" :options="this.partners" valuekey="id"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>Actief</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="is_active"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Deelnemers</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="participating_edit"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.showParticipatingFields">
                                <div class="column">
                                    <small>Onderstaande opties filteren de beschikbare deelnemers. Je limiteert hiermee waar
                                        kortingscodes voor deze campagne ingeleverd kunnen worden.</small>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.showParticipatingFields">
                                <div class="column is-one-third"><label>Branches</label></div>
                                <div class="column">
                                    <tags-input
                                        name="branche_field"
                                        v-model="branche_field"
                                        :add-on-key="[' ', ',']"
                                        :tags="form.participating_branches"
                                        :placeholder="null"
                                        add-only-from-autocomplete
                                        :autocomplete-items="filteredBranches"
                                        @tags-changed="newTags => form.participating_branches = newTags"
                                    />
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.showParticipatingFields">
                                <div class="column is-one-third"><label>Landen</label></div>
                                <div class="column">
                                    <tags-input
                                        name="country_field"
                                        v-model="country_field"
                                        :add-on-key="[' ', ',']"
                                        :tags="form.participating_countries"
                                        :placeholder="null"
                                        add-only-from-autocomplete
                                        :autocomplete-items="filteredCountries"
                                        @tags-changed="newTags => form.participating_countries = newTags"
                                    />
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.showParticipatingFields">
                                <div class="column is-one-third"><label>Postcodes</label></div>
                                <div class="column">
                                    <tags-input
                                        name="postal_codes_field"
                                        v-model="postal_codes_field"
                                        :add-on-key="[' ', ',']"
                                        :tags="form.participating_postal_codes"
                                        :placeholder="null"
                                        @tags-changed="newTags => form.participating_postal_codes = newTags"
                                    />
                                    <small>Vul een exacte postcode in als <code>3404WV</code> of een postcodegebied als
                                        <code>3404*</code>.</small>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Landingspagina</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="landing_page_active"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.form.landing_page_active === 1">
                                <div class="column is-one-third"><label>URL</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="landing_page_slug"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.form.landing_page_active === 1">
                                <div class="column is-one-third"><label>Omschrijving</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="landing_page_description" type="textarea"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>E-mail branding</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="mail_branding_active"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.form.mail_branding_active === 1">
                                <div class="column is-one-third"><label>Omschrijving</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="mail_description" type="textarea"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button>Opslaan</g-button>
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import TagsInput from '@johmun/vue-tags-input'
import {mapGetters} from 'vuex'
import {countriesApi} from "../../../api/Countries";
import {branchesApi} from "../../../api/Branches";

export default {
    components: {TagsInput},
    props: {
        id: String
    },
    data() {
        const object = this.$store.getters['adminCampaigns/getById'](this.id)
        const formData = object ? object : {
            name: null,
            partner_id: null,
            is_active: 0,
            participating_branches: [],
            participating_countries: [],
            participating_postal_codes: [],
            landing_page_active: 0,
            landing_page_slug: null,
            landing_page_description: null,
            mail_branding_active: 0,
            mail_description: null,
        }

        return {
            object: object,
            autocomplete_branches: [],
            autocomplete_countries: [],
            branche_field: '',
            country_field: '',
            postal_codes_field: '',
            form: new Form({
                participating_edit: 0,
                ...formData
            }),
        }
    },
    computed: {
        ...mapGetters({
            partners: 'adminPartners/all',
        }),
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.isCreateNewForm ? 'Toevoegen' : 'Aanpassen'
        },
        formEndpoint() {
            let endpoint = `/api/admin/campaign`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        },
        filteredBranches() {
            return this.autocomplete_branches.filter(i => {
                return i.text.toLowerCase().indexOf(this.branche_field.toLowerCase()) !== -1;
            });
        },
        filteredCountries() {
            return this.autocomplete_countries.filter(i => {
                return i.text.toLowerCase().indexOf(this.country_field.toLowerCase()) !== -1;
            });
        },
        showParticipatingFields() {
            return this.form.participating_edit === 1
        }
    },
    methods: {
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'adminCampaigns/add' : 'adminCampaigns/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
        formatData(form) {
            return form
        },
    },
    mounted() {
        branchesApi.getAll()
            .then(({data}) => {
                this.autocomplete_branches = data.map(branche => {
                    return {
                        text: branche.name,
                        value: branche.value
                    }
                })
            })

        countriesApi.getAll()
            .then(({data}) => {
                this.autocomplete_countries = data.map(country => {
                    return {
                        text: country.name,
                        value: country.value
                    }
                })
            })
    },
}
</script>
