<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">Cadeaubon</h1>
                    </div>

                    <g-form :form="form"
                            :action="`/api/admin/giftcard/${this.id}`"
                            method="patch"
                            prepopulate="true"
                            :before="parseData"
                            @prepopulated="prepopulateForm"
                            @success="this.destroy">

                        <div class="popup-body popup-rows">

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Bedrijf</label>
                                </div>
                                <div class="column is-vcentered">
                                    {{ form.company ? form.company.name : '' }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Product</label>
                                </div>
                                <div class="column is-vcentered">
                                    <div>
                                        {{ form.package ? 'Arrangement' : 'Cadeaubon' }}
                                        {{ form.package ? `(${form.package.title})` : '' }}
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Status</label>
                                </div>
                                <div class="column is-vcentered">
                                    {{ form.status | statusLabel }}
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Promotioneel</label>
                                </div>
                                <div class="column is-vcentered">
                                    <g-switch name="promotional"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Bericht</label>
                                </div>
                                <div class="column is-vcentered">
                                    <g-input name="message" type="textarea"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Vervaldatum</label>
                                </div>
                                <div class="column is-vcentered">
                                    <g-format type="date" name="expires_at"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Saldo</label>
                                </div>
                                <div class="column is-vcentered">
                                    {{ form.current_amount / 100 | currency }} van de
                                    {{ form.initial_amount / 100 | currency }}
                                </div>
                            </div>

                            <div class="columns" v-if="form.code_rotations">
                                <div class="column is-one-third">
                                    <label>Eerdere codes</label>
                                </div>
                                <div class="column is-vcentered">
                                    <ul>
                                        <li v-for="code in form.code_rotations">
                                            {{ code.previous_code }} | {{ code.created_at | localDateTime }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button>Opslaan</g-button>
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        </div>

                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        id: Number
    },

    data () {
        const formData = {
            id:             null,
            package:        null,
            company:        null,
            code_rotations: [],
            status:         null,
            message:        null,
            promotional:    null,
            expires_at:     null,
            current_amount: null,
            initial_amount: null,
        }

        return {
            form: new Form(formData)
        }
    },

    methods: {
        parseData(form) {
            form.expires_at = this.formatHumanToISO(form.expires_at)

            return form;
        },
        prepopulateForm({data}) {
            this.form.expires_at = this.formatDateToHuman(data.expires_at)
        },
        formatDateToHuman (date) {
            if (typeof date === 'undefined') return null

            const format = moment(date).format('DD-MM-YYYY')
            return format === 'Invalid date' ? '' : format
        },
        formatHumanToISO (date) {
            const format = moment(date, 'DD-MM-YYYY').toISOString()
            return format === 'Invalid date' ? '' : format
        },
    },

    filters: {
        statusLabel (status) {
            switch (status) {
                case 'issued':
                    return 'Uitgegeven'
                case 'redeemed':
                    return 'Verzilverd'
                case 'refunded':
                    return 'Terugbetaald'
                case 'expired':
                    return 'Verlopen'
                case 'blocked':
                    return 'Geblokkeerd'
                default:
                    return status
            }
        },
    }
}
</script>
