import Vue from 'vue'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import Errors from './Errors'

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data

        for (let field in data) {
            this[field] = data[field]
        }

        this.errors = new Errors()
        this.config = {}
    }

    async addField(field, value) {
        this.originalData[field] = value
        this[field] = value
    }

    async removeField(field) {
        delete this.originalData[field]
        delete this[field]
    }

    prepopulate(action) {
        return new Promise((resolve, reject) => {
            axios.get(action)
                .then(async (response) => {
                    await this.setFormData(response);

                    resolve(response)
                })
        })

    }

    setFormData(response) {
        return new Promise((resolve, reject) => {
            if (response.data.hasOwnProperty('data')) {
                response.data = response.data.data
            }

            for (let property in this.originalData) {
                if (response.data[property] === null) continue

                Vue.set(this, property, response.data[property])
            }

            resolve(response)
        })
    }

    /**
     * Fetch all relevant data for the form.
     */
    data(request) {
        let data = new FormData()
        let files = []

        data.append('_method', request.toUpperCase())

        for (let property in this.originalData) {
            if (!this.originalData.hasOwnProperty(property)) {
                continue
            }

            if (this[property] instanceof Blob || this[property] instanceof File) {
                data.append(property, this[property], this[property].name)
                continue
            }

            if (typeof this[property] === 'undefined') {
                continue
            }

            if (typeof this[property] !== 'string' && this[property] === null) {
                data.append(property, '')
                continue
            }

            if (isArray(this[property]) || isObject(this[property])) {
                forEach(this[property], (value, key1) => {
                    if (isObject(value)) {
                        forEach(value, (value2, key2) => {
                                data.append(`${property}[${key1}][${key2}]`, value2)
                        })
                    } else {
                            data.append(`${property}[${key1}]`, value)
                    }
                })
                continue
            }

            data.append(property, this[property])
        }

        for (let file of files) {
            for (let i = 0; i < file.length; i++) {
                if (typeof this[file][i] === 'undefined') {
                    continue
                }

                data.append(`${file}[${i}]`, this[file][i], this[file][i].name)
            }
        }

        return data
    }

    setErrors(errors = {}) {
        this.errors = new Errors(errors)
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = ''
        }

        this.errors.clear()
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url)
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url)
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url)
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url)
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        return new Promise((resolve, reject) => {
            axios.post(url, this.data(requestType), this.config)
                .then((response) => {
                    this.onSuccess(response.data)

                    resolve(response.data)
                })
                .catch(error => {
                    this.onFail(error.response.data)

                    reject(error)
                })
        })
    }

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess(data) {

    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.errors.clear(null)

        if (typeof errors.errors !== 'undefined') {
            this.errors.record(errors.errors)
        }
    }
}

export default Form
