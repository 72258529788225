<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.$t('collaboration_settle.settings.title') }}</h1>
                        <p>{{ this.$t('collaboration_settle.settings.description') }}</p>
                    </div>

                    <div>
                        <g-form
                            :form="form" method="post" :action="`/api/settlement-settings`"
                            :message="this.$t('notice.saved')"
                            prepopulate="true" @success="onSuccess">
                            <div class="popup-body popup-rows">
                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <strong>{{ this.$t('collaboration_settle.settings.issue_fee') }}</strong>
                                    </div>

                                    <div class="column">
                                        <g-format type="percentage"
                                                 name="settle_fee_issue_percentage"
                                                 :label="this.$t('collaboration_settle.settings.percentage')"/>
                                        <small>{{ this.$t('collaboration_settle.settings.issue_fee_description') }}</small>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <strong>{{ this.$t('collaboration_settle.settings.redeem_fee') }}</strong>
                                    </div>

                                    <div class="column">
                                        <g-format type="percentage"
                                                  name="settle_fee_redeem_percentage"
                                                  :label="this.$t('collaboration_settle.settings.percentage')"/>
                                        <small>{{ this.$t('collaboration_settle.settings.redeem_fee_description') }}</small>
                                    </div>
                                </div>

                            </div>

                            <div class="popup-footer">
                                <g-button>{{ this.$t('collaboration_settle.settings.save') }}</g-button>
                                <a @click.prevent="destroy" href="#"
                                   role="button">{{ this.$t('confirmation_dialog.deny') }}</a>
                            </div>
                        </g-form>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    data() {
        return {
            form: new Form({
                settle_fee_issue_percentage: null,
                settle_fee_redeem_percentage: null,
            }),
        }
    },

    computed: {},

    methods: {
        onSuccess() {
            this.destroy()
        }
    }
}
</script>
