<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <g-form
                    class="popup-dialog"
                    :form="form"
                    :action="`/api/admin/transaction/${this.id}/reverse`"
                    :prepopulate="true"
                    @success="this.destroy"
                >
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('admin.transactions.reverse.title') }}</h1>
                    </div>

                    <div class="popup-body">
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.reverse.id') }}</strong></div>
                            <div class="column">{{ id }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.reverse.amount') }}</strong></div>
                            <div class="column">{{ form.amount / 100 | currency }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.reverse.type') }}</strong></div>
                            <div class="column">{{ $t('admin.transactions.type_label.' + form.type) }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.reverse.date') }}</strong></div>
                            <div class="column">{{ $d(new Date(form.captured_at), 'long') }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>{{ $t('admin.transactions.reverse.description') }}</strong></div>
                            <div class="column">{{ form.description }}</div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <g-button>{{ $t('admin.transactions.reverse.reverse') }}</g-button>
                        <a @click.prevent="destroy" href="#" role="button">{{
                                $t('admin.transactions.reverse.cancel')
                            }}</a>
                    </div>
                </g-form>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "TransactionReverseForm",
    data() {
        return {
            form: new Form({
                amount: null,
                type: null,
                captured_at: null,
                description: null,
            })
        }
    },
    props: {
        id: {
            type: Number,
        },
    },
}
</script>

<style scoped>

</style>
