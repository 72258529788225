<template>
  <div class="tab-content" :class="{'blueprint-bg': blueprint}" v-if="active">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabbedViewItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    blueprint: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
.tab-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blueprint-bg {
    background: url('../../../images/layout/blueprint-bg.svg') repeat #F6F9FB;
    background-blend-mode: darken;

    // The background starts with a border on the left side. This can look funny if the container itself also has a border
    background-position-x: -5px;
}
</style>
