<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible">
			<div class="modal-background" @click="destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog">
					<div class="popup-title" v-if="title">
						<h1 class="title is-1">{{title}}</h1>
					</div>

					<div class="popup-body">
						{{text}}<br/><br/>

						<div class="code-box" @click="selectText('code-snippet')">
							<pre class="code-container"><code id="code-snippet">{{codeSnippet}}</code></pre>
						</div>
					</div>

					<div class="popup-footer">
						<button class="button is-rounded is-success" @click.prevent="destroy">
							Oké
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    export default {
        props:   {
            title:       {
                type: String
            },
            text:        {
                type: String,
            },
            codeSnippet: {
                type: String,
            }
        },
        methods: {
            selectText (containerid) {
                if (document.selection) {
                    const range = document.body.createTextRange()
                    range.moveToElementText(document.getElementById(containerid))
                    range.select()
                } else if (window.getSelection) {
                    const range = document.createRange()
                    range.selectNode(document.getElementById(containerid))
                    window.getSelection().removeAllRanges()
                    window.getSelection().addRange(range)
                }
            },
        }
    }
</script>
