<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>Partner</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" label="Naam"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Betaalaccount</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="payment_account" label="Betaalaccount"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Logo</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-file name="logo" label="Logo" accept="image/*"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button>Opslaan</g-button>
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: String
    },
    data() {
        const object = this.$store.getters['adminPartners/getById'](this.id)
        const formData = object ? object : {
            'name': null,
            'payment_account': null,
            'logo': null,
        }

        return {
            object: object,
            form: new Form(formData),
        }
    },
    computed: {
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.isCreateNewForm ? 'Toevoegen' : 'Aanpassen'
        },
        formEndpoint() {
            let endpoint = `/api/admin/partner`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    methods: {
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'adminPartners/add' : 'adminPartners/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
        formatData(form) {
            return form
        },
    },
}
</script>
