<template>
    <transition name="fade">
        <div class="g-modal" v-show="visible">
            <div class="g-modal-background"></div>
            <div class="container">
                <div class="g-modal-box">
                    <div class="box-header">
                        <div class="columns is-mobile is-gapless">
                            <div class="column is-narrow-mobile">
                                <h1 class="title is-1" v-if="this.$slots.title">
                                    <slot name="title"></slot>
                                </h1>
                            </div>
                            <div class="column">
                                <div class="right-nav">
                                    <ul class="g-modal-tabs is-hidden-mobile" v-if="tabs.length">
                                        <li
                                            v-for="(tab, index) in tabs"
                                            :ref="'modalLink' + index"
                                            @mouseover="setMarkerDimensions(index)"
                                            @mouseleave="setMarkerDimensions(tabActive)"
                                            @click="$emit('tabSelected', index)"
                                            :class="{ 'is-active': tabActive === index }"
                                        >
                                            <span>{{ tab.title }}</span>
                                        </li>
                                        <div class="marker" ref="marker"/>
                                    </ul>

                                    <div class="close" @click="$emit('destroy')"><i class="icon">close</i></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-main">
                        <slot>
                            <div class="split-screen">
                                <div v-if="$slots.settingArea" class="screen">
                                    <div class="content">
                                        <slot name="settingArea"></slot>
                                    </div>
                                </div>
                                <div v-if="$slots.previewArea" class="screen is-hidden-mobile">
                                    <div class="content">
                                        <slot name="previewArea"></slot>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>

                    <div class="box-footer">
                        <slot name="footer">
                            <span class="cancel" @click="$emit('destroy')">{{ $t(cancelLabel) }}</span>
                            <g-button
                                @click="$emit('success')"
                                :loading="successLoading"
                                :disabled="successDisabled"
                            >{{ $t(successLabel) }}
                            </g-button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Accordeon from "./Accordeon.vue";
import AccordeonItem from "./AccordeonItem.vue";
import TabbedViewItem from "./TabbedViewItem.vue";
import TabbedView from "./TabbedView.vue";

export default {
    name: "ModalLayout",
    components: {TabbedView, TabbedViewItem, AccordeonItem, Accordeon},
    props: {
        successLabel: {
            type: String,
            default: 'confirmation_dialog.confirm'
        },
        successDisabled: {
            type: Boolean,
            default: false
        },
        successLoading: {
            type: Boolean,
            default: false
        },
        cancelLabel: {
            type: String,
            default: 'confirmation_dialog.deny'
        },
        visible: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Array,
            default: []
        },
        tabActive: {
            type: Number,
            default: 0
        }
    },
    methods: {
        setMarkerDimensions(index) {
            let ref = `modalLink${index}`

            if (typeof (this.$refs[ref]) == 'undefined') {
                return
            }

            let tab = this.$refs[ref][0]
            let offset = tab.offsetLeft
            let width = tab.offsetWidth

            this.$refs.marker.style.width = width + 'px'
            this.$refs.marker.style.left = offset + 'px'
            this.$refs.marker.style.display = 'block'
        },
    },
    watch: {
        tabs() {
            this.$nextTick(() => {
                this.setMarkerDimensions(this.tabActive)
            })
        }
    }
}
</script>

<style scoped lang="scss">
$horizontalPadding: 2.1875rem;
$horizontalPaddingMobile: 1.2rem;

.g-modal {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    position: fixed;
    z-index: 250;
    display: flex;
    align-items: center;

    @media screen and (min-width: 769px) {
        padding: .8rem 0;
    }

    .g-modal-background {
        background-color: rgba(10, 10, 10, 0.86);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
    }

    .g-modal-box {
        height: 100vh;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        background: #FFF;

        @media screen and (min-width: 769px) {
            height: 95vh;
            border-radius: 12px;
        }
    }

    .container {
        overflow-x: scroll;
        max-height: 100%;
    }

    .box-header {
        border-bottom: 1px solid rgba(239, 239, 239, 0.94);

        .title.is-1 {
            font-size: 1.2rem;
            padding: 1.625rem $horizontalPaddingMobile 1.3125rem $horizontalPaddingMobile;

            @media screen and (min-width: 769px) {
                font-size: 1.4rem;
                padding: 1.625rem $horizontalPadding 1.3125rem $horizontalPadding;
                border-right: 2px solid #EFEFEF;
            }
        }

        .right-nav {
            height: 100%;
            display: flex;
            justify-content: flex-end;

            ul.g-modal-tabs {
                flex: 1;
                display: flex;
                position: relative;

                .marker {
                    bottom: 0;
                    position: absolute;
                    display: none;
                    border-bottom: 3px solid #DE134E;
                    height: 3px;
                    transition: width .3s, left .3s;
                }

                li {
                    padding: 0 1.4375rem;
                    display: flex;
                    align-items: center;
                    border-top: 2px solid #FFFFFF;
                    border-right: 1px solid #EFEFEF;
                    border-bottom: 3px solid #FFFFFF;

                    &.is-active {
                        color: #1F1F1F;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    span {
                        flex: 1;
                    }
                }
            }
        }

        .close {
            float: right;
            cursor: pointer;
            appearance: none;
            width: 4rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #EFEFEF;

            @media screen and (min-width: 769px) {
                width: 4.875rem;
            }

            &:hover {
                color: #1F1F1F;
            }

            .icon {
                font-size: 1.8rem;
            }
        }
    }

    .box-main {
        height: 100%;
        overflow: hidden;

        .split-screen {
            height: 100%;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 769px) {
                flex-direction: row;
            }

            .screen {
                height: 100%;

                @media screen and (min-width: 769px) {
                    flex: 1;
                }
            }
        }

        .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;

            @media screen and (min-width: 769px) {
                border-right: 2px solid #EFEFEF;
            }
        }

        ::v-deep {
            p {
                font-size: 1rem;
                padding: 1rem $horizontalPaddingMobile 0 $horizontalPaddingMobile;

                @media screen and (min-width: 769px) {
                    padding: 1rem $horizontalPadding 0 $horizontalPadding;
                }
            }

            label.group-label {
                color: #313131;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: .3rem;
                display: block;

                small {
                    color: #6A7272;
                    font-size: .75rem;
                    display: block;
                }
            }

            form .field {
                margin-bottom: .75rem;

                label.label.active {
                    color: #4F5050;
                }
            }

            .field-body > div {
                flex: 1;

                @media screen and (min-width: 769px) {
                    &:not(:last-child) {
                        margin-right: 0.75rem;
                    }
                }
            }

            input,
            .select select,
            textarea,
            .vue-tags-input .ti-input {
                box-shadow: none;
            }
        }
    }

    .box-footer {
        font-size: 0.9375rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem $horizontalPaddingMobile;
        border-top: 1px solid rgba(239, 239, 239, 0.94);

        @media screen and (min-width: 769px) {
            padding: 1rem $horizontalPadding;
        }

        .cancel {
            color: #6A7272;
            margin-right: 0.8125rem;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
</style>
