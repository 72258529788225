<template>
    <transition name="fade">
        <div class="modal model-large is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                    </div>

                    <div class="popup-body">
                        <div class="columns">
                            <div class="column is-3">
                                <label>{{ $t('digital.delivery_log_form.date') }}</label>
                            </div>
                            <div class="column is-4">
                                <label>{{ $t('digital.delivery_log_form.email') }}</label>
                            </div>
                            <div class="column is-3">
                                <label>{{ $t('digital.delivery_log_form.status') }}</label>
                            </div>
                            <div class="column is-2">
                                <label>{{ $t('digital.delivery_log_form.action') }}</label>
                            </div>
                        </div>

                        <div v-for="delivery in orderDeliveries(this.$props.order_id)" class="columns">
                            <div class="column is-3">
                                {{ delivery.created_at | localDateTime }}
                            </div>
                            <div class="column is-4">
                                {{ delivery.email_recipient }}
                            </div>
                            <div class="column is-3">
                                {{ delivery.status }}
                            </div>
                            <div class="column is-2">
                                <b-tooltip :label="$t('digital.delivery_log_form.resend')" position="is-left">
                                    <button class="button" @click.prevent="openResendForm(delivery)">
                                        <i class="icon">forward_to_inbox</i>
                                    </button>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <button class="button is-rounded is-success" @click.prevent="destroy">
                            {{ $t('digital.delivery_log_form.submit') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        order_id: String
    },

    computed: {
        ...mapGetters({
            orderDeliveries: 'orderDeliveries/getByOrderId',
        }),
        formTitle () {
            return this.$t('digital.delivery_log_form.title')
        },
    },

    mounted () {
        this.$store.dispatch('orderDeliveries/loadAllByOrderId', {
            order_id: this.$props.order_id,
        })
    },

    methods: {
        openResendForm (delivery) {
            this.$popup('PromotionalDeliveryLogResendForm', {
                order_id:    delivery.order_id,
                delivery_id: delivery.id,
            })
        },
    }
}
</script>
