<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible" id="popup-email">
			<div class="modal-background" @click="this.destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog" v-if="response">
					<div class="popup-body">
                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.method') }}</strong>
                            </div>

                            <div class="column">
                                <strong>{{ response.method }}</strong>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.status') }}</strong>
                            </div>

                            <div class="column">
                                <strong class="tag" :class="getClassByStatus(response.status)">{{ response.status }}</strong>
                                <small class="pl-1" v-if="getStatusMessageByCode(response.status) !== ''">
                                    <a :href="`https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${response.status}`"
                                       target="_blank" rel="noreferrer">
                                        {{ getStatusMessageByCode(response.status) }}
                                    </a>
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.path') }}</strong>
                            </div>

                            <div class="column">
                                <small>
                                    <span class="has-text-grey">{{ formatUrl(response.url)[0] }}</span><b class="redacted-tag" v-html="formatUrl(response.url)[1]"></b><span class="has-text-grey">{{ formatUrl(response.url)[2] }}</span>
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.date') }}</strong>
                            </div>

                            <div class="column">{{ response.created_at }}</div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.execution_time') }}</strong>
                            </div>

                            <div class="column">
                                {{ response.duration.toFixed(3) }} {{ $t('developers.log_popup.execution_time_suffix') }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.log_popup.identifier') }}</strong>
                            </div>

                            <div class="column">
                                {{ this.$props.id }}
                            </div>
                        </div>

                        <hr>

                        <label>{{ $t('developers.log_popup.context') }}</label>

                        <div class="mt-3">
                            <a v-if="response && response.request.parameters === null || response.request.parameters === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.log_popup.request_parameters') }}
                            </a>

                            <a href="#" @click.prevent="details.showParameters = !details.showParameters" v-else>
                                <i class="icon">{{ details.showParameters ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.log_popup.request_parameters') }}
                            </a>
                            <Prism language="json" v-if="details.showParameters">{{ response ? prettify(response.request.parameters) : '' }}</Prism>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.query === null || response.request.query === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.log_popup.request_query') }}
                            </a>

                            <a href="#" @click.prevent="details.showQuery = !details.showQuery" v-else>
                                <i class="icon">{{ details.showQuery ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.log_popup.request_query') }}
                            </a>
                            <Prism language="json" v-if="details.showQuery">{{ response ? prettify(response.request.query) : '' }}</Prism>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.body === null || response.request.body === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.log_popup.request_body') }}
                            </a>

                            <a href="#" @click.prevent="details.showBody = !details.showBody" v-else>
                                <i class="icon">{{ details.showBody ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.log_popup.request_body') }}
                            </a>
                            <Prism language="json" v-if="details.showBody">{{ response ? prettify(response.request.body) : '' }}</Prism>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.response === null || response.request.response === '[]'"
                               class="disabled-link">{{ $t('developers.log_popup.response_body') }}</a>

                            <a href="#" @click.prevent="details.showResponse = !details.showResponse" v-else>
                                <i class="icon">{{ details.showResponse ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.log_popup.response_body') }}
                            </a>
                            <Prism language="json" v-if="details.showResponse">{{ response ? prettify(response.request.response) : '' }}</Prism>
                        </div>
					</div>

					<div class="popup-footer">
                        <button class="button is-rounded" @click="this.destroy">
                            {{ $t('developers.log_popup.close') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style>
@import 'prismjs/themes/prism.min.css';
</style>

<script>
    import * as Http from '../../../constants/Http'

    import 'prismjs'
    import 'prismjs/themes/prism.css'
    import 'prismjs/components/prism-json.min'

    import Prism from 'vue-prism-component'

    export default {
        components: {
            Prism
        },

        props: {
            id: {
                type: String,
			},
		},

		data() {
            return {
                response: null,
                details: {
                    showQuery:      false,
                    showParameters: false,
                    showBody:       false,
                    showResponse:   false
                }
			}
		},

		async mounted() {
            const request = await axios.get(`/api/company/${this.$store.getters['user/activeCompanyId']}/api-client-log/${this.$props.id}`)

			if (request.status !== 200) {
				this.destroy()
				return false
			}

			this.response = request.data.data
		},

		methods: {
            getStatusMessageByCode(code) {
                return Http.STATUS_CODES[code] ? Http.STATUS_CODES[code] : ''
            },
            formatUrl(url) {
                try {
                    const parts = new URL(url)
                    const params = `${parts.search ? parts.search : ''}`
                    let suffix = parts.pathname

                    if (suffix.includes('XXXXXXXXXXXX')) {
                        const identifier = parts.pathname.split('XXXXXXXXXXXX')[1].substring(0, 4)
                        suffix = parts.pathname.replace(`XXXXXXXXXXXX${identifier}`, 'XXXXXXXXXXXX')
                        suffix = suffix.replace('XXXXXXXXXXXX', `<div class="tag primary">XXXXXXXXXXXX${identifier}</div>`)
                    }

                    return [
                        parts.hostname ?? parts.host,
                        suffix,
                        params
                    ]
                } catch (e) {
                    return [
                        url,
                        '',
                        ''
                    ]
                }
            },
            prettify(data) {
                try {
                    return JSON.stringify(JSON.parse(data), null, 4)
                } catch (e) {
                    return data
                }
            },
            getClassByStatus(type) {
                if (type >= 200 && type < 300) {
                    // Success
                    return 'is-success'
                } else if (type >= 400 && type < 500) {
                    // Client error
                    return 'is-warning'
                } else if (type >= 500 && type < 600) {
                    // Server error
                    return 'is-danger'
                }

                return 'is-light'
            },
		}
    }
</script>
