<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">Cadeauboncode roteren</h1>
                    </div>

                    <g-form :form="form"
                            :action="`/api/admin/giftcard/${this.id}/rotate`"
                            method="post"
                            @success="this.destroy">

                        <div class="popup-body">
                            Weet je zeker dat je de code van deze cadeaubon wilt roteren? De oude cadeaubon is niet meer te gebruiken.
                        </div>

                        <div class="popup-footer">
                            <g-button>Code roteren</g-button>
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        </div>

                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: Number,
        status: String,
    },

    data() {
        return {
            form: new Form({})
        }
    },
}
</script>
