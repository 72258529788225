<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div @click="destroy" class="modal-background"></div>
            <div class="modal-content">

                <g-form :action="`/api/admin/order/${order.id}`"
                        :before="beforeSubmit"
                        :form="form"
                        @fail="loading = false"
                        @success="afterSubmit"
                        message="Wijzigingen succesvol opgeslagen"
                        method="patch">

                    <div class="popup-dialog">

                        <div class="popup-title">
                            <h1 class="title is-1">Bestelling aanpassen</h1>
                        </div>

                        <div class="popup-body">

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>E-mail adres</label>
                                </div>
                                <div class="column">
                                    <g-input name="email" type="email"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Taal</label>
                                </div>
                                <div class="column">
                                    <g-select
                                        label="Taal"
                                        :options="locales"
                                        name="language"
                                    />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Rapportagelabel</label>
                                </div>
                                <div class="column">
                                    <tags-input v-model="editableTag"
                                                :tags="form.tags"
                                                :max-tags="10"
                                                :add-on-key="[';', ',', 13]"
                                                :placeholder="null"
                                                :autocomplete-items="tagsAutocomplete"
                                                :autocomplete-min-length="0"
                                                autocomplete="off"
                                                @tags-changed="newTags => form.tags = newTags"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <label>Heeft factuur</label>
                                </div>
                                <div class="column is-vcentered">
                                    <g-switch name="payment_specification" class="is-pulled-right" no-field/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-input label="Bedrijfsnaam" name="invoice_company_name"
                                             :disabled="showInvoiceAddressFields"/>
                                </div>
                                <div class="column">
                                    <g-input label="Naam" name="invoice_name" :disabled="showInvoiceAddressFields"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <g-input label="Straatnaam" name="invoice_street"
                                             :disabled="showInvoiceAddressFields"/>
                                </div>
                                <div class="column">
                                    <g-input name="invoice_house_number" label="Huisnummer"
                                             :disabled="showInvoiceAddressFields" max="10"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-format label="Postcode"
                                              name="invoice_zip"
                                              type="postcode"
                                              ref="invoice_zip_format"
                                              :disabled="showInvoiceAddressFields"/>
                                </div>
                                <div class="column">
                                    <g-input label="Plaats" name="invoice_city" :disabled="showInvoiceAddressFields"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-input label="Extra adresregel" name="invoice_addition"
                                             :disabled="showInvoiceAddressFields"/>
                                </div>
                            </div>

                            <hr>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>Bezorgadres</label>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-input label="Bedrijfsnaam" name="company_name"/>
                                </div>
                                <div class="column">
                                    <g-input label="Naam" name="name"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <g-input label="Straatnaam" name="street"/>
                                </div>
                                <div class="column">
                                    <g-input name="house_number" label="Huisnummer" max="10"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-format label="Postcode"
                                              name="zip"
                                              type="postcode"
                                              ref="zip_format"/>
                                </div>
                                <div class="column">
                                    <g-input label="Plaats" name="city"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <g-input label="Extra adresregel" name="addition"/>
                                </div>
                            </div>

                        </div>

                        <div class="popup-footer">

                            <button :class="{ 'is-loading': loading }" class="button is-rounded is-success">
                                Opslaan
                            </button>
                            <a @click.prevent="destroy" href="/cancel" role="button">Annuleren</a>

                        </div>
                    </div>
                </g-form>
            </div>


        </div>
    </transition>
</template>

<script>
import {mapGetters} from "vuex";
import TagsInput from "@johmun/vue-tags-input";

export default {
    components: {TagsInput},
    props: {
        order: Object,
    },
    data() {
        return {
            loading: false,
            editableTag: '',
            tagsAutocomplete: [],

            form: new Form({
                email: '',
                language: '',
                payment_specification: 0,
                tags: [],

                invoice_company_name: '',
                invoice_name: '',
                invoice_street: '',
                invoice_house_number: '',
                invoice_zip: '',
                invoice_city: '',
                invoice_addition: '',

                company_name: '',
                name: '',
                street: '',
                house_number: '',
                zip: '',
                city: '',
                addition: '',
            })
        }
    },
    mounted() {
        this.form.email = this.order.email
        this.form.language = this.order.language
        this.form.payment_specification = this.order.payment_specification
        this.form.tags = this.order.tags.map(tag => {
            return { text: tag }
        })

        if (this.order.address) {
            this.form.company_name = this.order.address.company_name
            this.form.name = this.order.address.name
            this.form.street = this.order.address.street
            this.form.house_number = this.order.address.house_number
            this.form.city = this.order.address.city
            this.form.addition = this.order.address.addition
            this.form.zip = this.order.address.zip
            const formattedZIP = this.$refs.zip_format.formatValue(this.order.address.zip)
            this.$refs.zip_format.setValue(formattedZIP)
        }

        if (this.order.payment_specification) {
            this.form.invoice_company_name = this.order.company_name
            this.form.invoice_name = this.order.name
            this.form.invoice_street = this.order.street
            this.form.invoice_house_number = this.order.house_number
            this.form.invoice_city = this.order.city
            this.form.invoice_addition = this.order.addition

            const formattedZIP = this.$refs.zip_format.formatValue(this.order.zip)
            this.$refs.invoice_zip_format.setValue(formattedZIP)
        }

        this.tagsAutocomplete = this.order.tags_autocomplete.map(tag => {
            return { text: tag }
        })
    },

    computed: {
        ...mapGetters({
            locales: "application/availableLocalesTranslated",
        }),
        showInvoiceAddressFields() {
            return !this.form.payment_specification
        }
    },

    methods: {
        beforeSubmit(form) {
            this.loading = true

            form.payment_specification = form.payment_specification ? 1 : 0

            if (!form.payment_specification) {
                this.form.invoice_company_name = ''
                this.form.invoice_name = ''
                this.form.invoice_street = ''
                this.form.invoice_house_number = ''
                this.form.invoice_city = ''
                this.form.invoice_zip = ''
                this.form.invoice_addition = ''
            }

            form.tags = form.tags.map(tag => {
                return tag.text
            })

            return form
        },

        afterSubmit() {
            this.destroy(this.form)
        }
    }
}
</script>
