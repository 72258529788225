<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                        <p v-text="$t('digital.create_form.description')"/>
                    </div>

                    <g-form :action="formEndpoint"
                            :form="form"
                            :method="formMethod"
                            :before="beforeSubmit"
                            @success="formSuccess"
                    >
                        <div class="popup-body popup-rows">

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.location') }}</label></div>
                                <div class="column">
                                    <g-select name="location_id"
                                              :label="$t('digital.create_form.location')"
                                              :options="locationOptions"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.worth') }}</label></div>
                                <div class="column">
                                    <g-select :options="typeOptions" name="type"/>

                                    <g-format
                                        v-if="form.type === 'amount'"
                                        type="currency"
                                        name="amount"
                                        :label="$t('digital.create_form.amount')"/>

                                    <g-select
                                        v-if="form.type === 'package'"
                                        :options="packageOptions"
                                        name="package_id"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.quantity') }}</label></div>
                                <div class="column">
                                    <g-input type="number" min="1" max="100" name="quantity"
                                             :label="$t('digital.create_form.quantity_label')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.message') }}</label></div>
                                <div class="column">
                                    <g-input :label="$t('digital.create_form.message_label')"
                                             name="message" type="textarea" maxlength="300"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.expiration') }}</label></div>
                                <div class="column">
                                    <g-select name="expiration"
                                              :options="expirationOptions"
                                              :label="$t('digital.create_form.expiration_label')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.create_form.accounting') }}</label></div>
                                <div class="column">
                                    <g-input name="reference" :label="$t('digital.create_form.reference')"/>

                                    <tags-input v-model="editableTag"
                                                :tags="form.tags"
                                                :add-on-key="[';', ',', 13]"
                                                :max-tags="10"
                                                :placeholder="$t('digital.create_form.reporting_tags')"
                                                :autocomplete-items="tagsAutocomplete"
                                                :autocomplete-min-length="0"
                                                autocomplete="off"
                                                class="mb-2"
                                                @tags-changed="newTags => form.tags = newTags"/>

                                    <g-switch name="mark_promotional"
                                              :label="$t('digital.create_form.mark_promotional')"
                                              :subtitle="$t('digital.create_form.mark_promotional_subtitle')"
                                    />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth"></div>
                                <div class="column">
                                    <g-switch name="accept_pricing" :label="$t('digital.create_form.pricing_agreement')"
                                              :subtitle="formConditionsText"/>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button :disabled="formDisabled">{{ $t('digital.create_form.submit') }}</g-button>
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('digital.create_form.cancel') }}</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from 'vuex'
import TagsInput from "@johmun/vue-tags-input";
import posthog from "posthog-js";

export default {
    components: {TagsInput},
    props: {
        id: String
    },

    data() {
        const object = this.$store.getters['promotionalOrders/getById'](this.id)
        const formData = object ? object : {
            'quantity': null,
            'type': 'amount',
            'package_id': null,
            'location_id': null,
            'amount': null,
            'message': null,
            'reference': null,
            'mark_promotional': true,
            'tags': [],
            'expiration': this.$store.getters['user/activeCompany'].gift_card_expiration,
            'accept_pricing': false,
        }

        return {
            object: object,
            form: new Form(formData),
            editableTag: '',
            expirationOptions: [
                {
                    name: this.$tc('digital.create_form.expiration_months', 1),
                    value: 30
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 2),
                    value: 30 * 2
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 3),
                    value: 30 * 3
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 4),
                    value: 30 * 4
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 5),
                    value: 30 * 5
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 6),
                    value: 30 * 6
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 7),
                    value: 30 * 7
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 8),
                    value: 30 * 8
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 9),
                    value: 30 * 9
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 10),
                    value: 30 * 10
                },
                {
                    name: this.$tc('digital.create_form.expiration_months', 11),
                    value: 30 * 11
                },

                {
                    name: this.$tc('digital.create_form.expiration_years', 1),
                    value: 365
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 2),
                    value: 365 * 2
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 3),
                    value: 365 * 3
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 4),
                    value: 365 * 4
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 5),
                    value: 365 * 5
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 6),
                    value: 365 * 6
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 7),
                    value: 365 * 7
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 8),
                    value: 365 * 8
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 9),
                    value: 365 * 9
                },
                {
                    name: this.$tc('digital.create_form.expiration_years', 10),
                    value: 365 * 10
                },
            ],
            packageOptions: [],
            locationOptions: [
                {
                    name: this.$t('digital.create_form.no_location'),
                    value: null
                }
            ],
            typeOptions: [{
                name: this.$t('digital.create_form.amount'),
                value: 'amount'
            }],
        }
    },

    computed: {
        ...mapGetters({
            locationsSelect: 'locations/allIssuePointSelect',
            packages: 'packages/all',
            objectPrice: 'promotionalOrders/getPrice',
            reportingTags: 'promotionalOrders/getReportingTags'
        }),
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.$t('digital.create_form.title')
        },
        formConditionsText() {
            return this.$t('digital.create_form.conditions', {
                price: this.$options.filters.currency(this.objectPrice / 100)
            })
        },
        formEndpoint() {
            let endpoint = `/api/company/${this.$store.getters['user/activeCompanyId']}/promotional`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        },
        formDisabled() {
            return !this.form.accept_pricing
        },
        tagsAutocomplete() {
            console.log(this.reportingTags)
            return this.reportingTags.map(tag => {
                return {
                    text: tag,
                }
            })
        },
    },

    created() {
        this.$store.dispatch('packages/loadAll')
            .then(() => {
                this.packages.forEach((item) => {
                    this.form.package_id = item.id
                    this.packageOptions.push({
                        name: item.title,
                        value: item.id
                    })
                })

                if (this.packages.length) {
                    this.typeOptions.push({
                        name: this.$t('digital.create_form.package'),
                        value: 'package'
                    })
                }
            })

        this.$store.dispatch('locations/loadAll').then(() => {
            this.locationOptions = [
                ...this.locationOptions,
                ...this.locationsSelect
            ]
        })
    },

    methods: {
        beforeSubmit(form) {
            form.tags = form.tags.map(tag => {
                return tag.text
            })

            return form
        },
        formSuccess(response) {
            posthog.capture(
                'created_digital_gift_cards',
                {
                    $set_once: { has_created_digital_gift_cards: true },
                }
            )

            const action = this.isCreateNewForm ? 'promotionalOrders/add' : 'promotionalOrders/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
    },
}
</script>
