<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title" v-if="title">
                        <h1 class="title is-1">{{ title }}</h1>
                    </div>

                    <div class="popup-body">
                        <vue-simple-markdown :source="text"></vue-simple-markdown>
                    </div>

                    <div class="popup-footer">
                        <button @click="confirmation" class="button is-rounded is-success"
                                :class="{'is-loading': this.isLoading}">
                            {{ this.$t('confirmation_dialog.confirm') }}
                        </button>
                        <a @click.prevent="destroy" href="#" role="button">{{ this.$t('confirmation_dialog.deny') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title:          {
            type: String
        },
        text:           {
            type: String,
        },
        onConfirmation: {
            type:     Function,
            required: true
        }

    },
    data () {
        return {
            isLoading: false
        }
    },
    methods: {
        async confirmation () {
            this.isLoading = true

            this.onConfirmation()
                .then((data) => {
                    this.$emit('acceptanceSuccessful', data)

                    this.destroy(false)
                })
                .catch((data) => {
                    this.$buefy.toast.open(this.$t('notice.error'))

                    this.$emit('acceptanceFailed', data)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style lang="scss">
.popup-body {
    ul {
        list-style-type: initial;
        padding-left: 2em;
        margin: 1em 0;
    }
}
</style>
