<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible">
			<div class="modal-background" @click="destroy"></div>
			<div class="modal-content location-settings">

				<g-form :action="`/api/company/${this.$store.getters['user/activeCompanyId']}/restocking/${location.id}`"
						method="patch"
						:form="form"
						@success="onSuccess"
						:message="$t('company_locations_settings.toast')"
						@fail="loading = false"
						:before="() => loading = true">

					<div class="popup-dialog">

						<div class="popup-title">
							<h1 class="title is-1 mb-2">
                                {{ $t('company_locations_settings.title') }}
                            </h1>
                            <h3 class="title is-3">
                                {{ this.location.street }} {{ this.location.house_number }} {{ this.location.addition ? this.location.addition : '' }}
                            </h3>
						</div>

						<div class="popup-body popup-rows">

                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <img :src="gift_card_preview" alt="Wrapping" class="giftcard-preview-left">
                                    <span class="option-title">{{ $t('company_locations_settings.auto_restock_gift_cards') }}</span>
                                    <br/>

                                    <span class="giftcard-sleeve-pricing">
                                        {{ $t('company_locations_settings.gift_card_price', { amount: this.$options.filters.currency(blanco_giftcard_price / 100) }) }}
                                    </span>
                                </div>
                                <div class="column is-vcentered">
                                    <g-switch name="auto_restock_giftcards" no-field/>
                                </div>
                            </div>

                            <div class="columns" v-show="this.restockWrapping !== null">
                                <div class="column is-two-thirds">
                                    <img :src="wrappingImage"
                                         alt="Gift card" class="giftcard-preview-left">
                                    <span class="option-title">{{ $t('company_locations_settings.auto_restock_wrappings') }}</span>
                                    <br/>

                                    <span class="giftcard-sleeve-pricing">
                                        {{ $t('company_locations_settings.wrapping_price', { amount: this.$options.filters.currency(wrappingPrice / 100), name: wrappingName }) }}<br/>

                                        <a href="#" class="item-link"
                                           v-on:click.prevent="selectWrapping">{{ $t('company_locations_settings.change_wrapping') }}</a>
                                    </span>
                                </div>
                                <div class="column is-vcentered">
                                    <g-switch name="auto_restock_wrappings" :disabled="!form.auto_restock_giftcards" no-field/>
                                </div>
                            </div>

							<div class="columns">
								<div class="column is-two-thirds">
									<label>{{ $t('company_locations_settings.auto_restock_threshold') }}</label>
								</div>
								<div class="column">
									<g-input name="minimum_stock" type="number" :disabled="!form.auto_restock_giftcards" animation="false" />
								</div>
							</div>

							<div class="columns">
								<div class="column is-two-thirds">
									<label>
                                        {{ $t('company_locations_settings.auto_restock_quantity') }}
                                    </label>
								</div>
								<div class="column">
									<g-select name="restock_amount" :options="restockOptions"
											  :disabled="!form.auto_restock_giftcards"/>
								</div>
							</div>
						</div>

						<div class="popup-footer">

							<button class="button is-rounded is-success" :class="{ 'is-loading': loading }">
                                {{ $t('company_locations_settings.submit') }}
							</button>
							<a role="button" @click.prevent="destroy" href="/cancel">{{ $t('company_locations_settings.cancel') }}</a>

						</div>
					</div>
				</g-form>
			</div>


		</div>
	</transition>
</template>

<script>
    export default {
        props:   {
            location: {
                type: Object,
                required: true
            },
            restockOptions: {
                type: Array,
                required: true
			},
            formData: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                loading: false,
                wrappings: [],
                blanco_giftcard_price: 0,
                gift_card_preview: null,

                form: new Form({
                    auto_restock_giftcards: 0,
                    auto_restock_wrappings: 0,
                    minimum_stock:          '5',
                    restock_amount:         '50',
                    restock_wrapping_id:    null
                })
            }
        },
        computed: {
            restockWrapping() {
                if(
                    this.form.restock_wrapping_id === null ||
                    !this.wrappings.length
                ) {
                     return null;
                }

                return this.wrappings.find(wrapping => wrapping.id === this.form.restock_wrapping_id) || null
            },
            wrappingPrice () {
                return this.restockWrapping ? this.restockWrapping.company_price : null
            },
            wrappingName () {
                return this.restockWrapping ? this.restockWrapping.name : ''
            },
            wrappingImage () {
                return this.restockWrapping ? this.restockWrapping.image : ''
            },
        },
        mounted() {
            if (typeof this.restockOptions[0] !== 'undefined') {
                this.form.restock_amount = this.restockOptions[0].value
            }

            this.wrappings             = this.formData.wrappings
            this.blanco_giftcard_price = this.formData.blanco_giftcard_price
            this.gift_card_preview     = this.formData.gift_card_preview

            this.form.auto_restock_giftcards = this.formData.auto_restock_giftcards
            this.form.auto_restock_wrappings = this.formData.auto_restock_wrappings
            this.form.minimum_stock          = this.formData.minimum_stock
            this.form.restock_amount         = this.formData.restock_amount
            this.form.restock_wrapping_id    = this.formData.restock_wrapping_id

            if (this.wrappings.length < 1) {
                return
            }

            if (this.restockWrapping === null) {
                this.form.restock_wrapping_id = this.formData.wrappings[0].id
            }
        },
        watch: {
            'form.auto_restock_giftcards' (newState) {
                if (newState === 0) {
                    this.form.auto_restock_wrappings = 0
                }
            }
        },
        methods: {
            selectWrapping () {
                if (this.wrappings.length < 1) {
                    return
                }

                const popup = this.$popup('WrappingBrowser', {
                    wrappings: this.wrappings
                })

                popup.$on('selected', (wrappingId) => {
                    this.form.restock_wrapping_id = wrappingId
                })
            },

            onSuccess() {
                this.$emit('updated')
                this.destroy()
            }
        },
    }
</script>
