<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>URL</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="slug" label="Url"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Lidmaatschap</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-select name="membership_id" :options="this.memberships" valuekey="id"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>E-mails</label></div>
                                <div class="column">
                                    <g-input label="E-mailadressen (één per regel)"
                                             name="recipients" type="textarea"/>
                                    <p class="help">
                                        Deze e-mailadressen ontvangen een notificatie zodra er een aanmelding plaatsvindt via de affiliate-link.
                                    </p>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Actief</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="is_active"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button>Opslaan</g-button>
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {adminMembershipsApi} from "../../../api/admin/Memberships";

export default {
    props: {
        id: String
    },
    data() {
        const object = this.$store.getters['adminAffiliates/getById'](this.id)
        const formData = object ? object : {
            'slug': null,
            'is_active': false,
            'membership_id': null,
            'recipients': null,
        }

        return {
            memberships: [
                {
                    id: null,
                    name: 'Geen',
                }
            ],
            object: object,
            form: new Form(formData),
        }
    },
    computed: {
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.isCreateNewForm ? 'Toevoegen' : 'Aanpassen'
        },
        formEndpoint() {
            let endpoint = `/api/admin/affiliate`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    mounted() {
        adminMembershipsApi.getAll()
            .then(({data}) => {
                const memberships = data.data;

                memberships.forEach((membership) => {
                    this.memberships.push({
                        id: membership.id,
                        name: `${membership.package.name} (${membership.plan.name})`
                    })
                })
            })
    },
    methods: {
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'adminAffiliates/add' : 'adminAffiliates/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
        formatData(form) {
            return form
        },
    },
}
</script>
