<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" :message="this.$t('notice.saved')">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.invoice_presets.name') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" :label="this.$t('admin.invoice_presets.name')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.invoice_presets.description') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="description"
                                                 :label="this.$t('admin.invoice_presets.description')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.invoice_presets.ledger_account') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-select name="ledger_account_id"
                                                  :options="this.ledgerAccountsPrefixedWithCode"
                                                  valuekey="id"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.invoice_presets.amount') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-format ref="format" :label="this.$t('admin.invoice_presets.amount')"
                                                  name="amount" type="positiveOrNegativeCurrency"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <g-button>{{ this.$t('submit_dialog.submit') }}</g-button>
                            <a @click.prevent="destroy" href="#" role="button">{{ this.$t('submit_dialog.cancel') }}</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    props: {
        id: String
    },
    data() {
        const object = this.$store.getters['adminInvoicePresets/getById'](this.id)
        const formData = object ? object : {
            'name': null,
            'description': null,
            'amount': null,
            'ledger_account_id': null,
        }

        return {
            object: object,
            form: new Form(formData),
        }
    },
    computed: {
        ...mapGetters({
            ledger_accounts: 'adminLedgerAccounts/all',
        }),
        ledgerAccountsPrefixedWithCode() {
            return this.ledger_accounts.map(ledgerAccount => {
                return {
                    ...ledgerAccount,
                    name: `${ledgerAccount.code} - ${ledgerAccount.name}`,
                }
            })
        },
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.isCreateNewForm ? this.$t('admin.invoice_presets.create.title') : this.$t('admin.invoice_presets.edit.title')
        },
        formEndpoint() {
            let endpoint = `/api/admin/invoice_preset`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    methods: {
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'adminInvoicePresets/add' : 'adminInvoicePresets/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
        formatData(form) {
            return form
        },
    },
}
</script>
