import find from 'lodash/find'
import Router from './utils/Router'
import store from './store'

export const getRoute = (name, full = false) => {
    if (full) {
        let found = find(Router.options.routes, {name})

        if (typeof found !== 'undefined') {
            return found
        }
    }

    return {name}
}

export const allowedRoute = (name) => {
    // Finds route with name
    const route = getRoute(name, true)

    if (store.getters['user/isGiftyAdmin'] === true) {
        return true
    }

    // Hide specific routes from collaborative accounts
    if (
        typeof route.meta !== 'undefined' &&
        typeof route.meta.hide_from_collaborative_accounts !== 'undefined' &&
        store.getters['user/isAuthenticated'] &&
        store.getters['user/activeCompany'].is_collaborative
    ) {
        return false
    }

    // If there are permissions for the route, check if it should be displayed
    if (typeof route.meta !== 'undefined' && typeof route.meta.permissions !== 'undefined') {
        const permissionAllowed = route.meta.permissions.map((permission) => store.getters['user/hasPermission'](permission))

        return false === permissionAllowed.includes(false)
    }

    return true
}

export const getRedirectable = (route) => {
    const split        = route.name.split('.')
    const target       = split[split.length - 1]
    const subItems     = menuItems[target].submenu_items ?? []
    const firstAllowed = subItems.find(item => allowedRoute(item.route_name) === true)
    let redirectName   = firstAllowed ? firstAllowed.route_name : 'home'

    return getRoute(redirectName)
}

export const menuItems = {
    giftcards: {
        title:      'navigation.transactions',
        route_name: 'transactions',
        main:       true,
    },

    shop: {
        title:                             'navigation.shop',
        route_name:                        'shop',
        main:                              true,
        hide_from_collaborative_companies: true,
        submenu_items:                     [
            {
                title:      'navigation.shop_digital',
                route_name: 'shop.digital',
            },
            {
                title:      'navigation.shop_stock',
                route_name: 'shop.stock',
            },
            {
                title:      'navigation.shop_restocking',
                route_name: 'shop.restocking',
            },
        ],
    },

    design: {
        title:                             'navigation.design',
        route_name:                        'settings.giftcard.builder',
        main:                              true,
        hide_from_collaborative_companies: true,
        submenu_items:                     [
            {
                title:      'navigation.design_giftcard',
                route_name: 'settings.giftcard.builder',
            },
            {
                title:      'navigation.design_wrapping',
                route_name: 'settings.giftcard.wrapping',
            },
            {
                title:      'navigation.design_wallet',
                route_name: 'settings.giftcard.wallet',
            },
        ],
    },

    module: {
        title:                             'navigation.module',
        route_name:                        'settings.module',
        main:                              true,
        hide_from_collaborative_companies: true,
        submenu_items:                     [
            {
                title:      'navigation.module_styling',
                route_name: 'settings.module.styling',
            },
            {
                title:      'navigation.module_placement',
                route_name: 'settings.module.placement',
            },
            {
                title:      'navigation.module_packages',
                route_name: 'settings.module.packages',
            },
            {
                title:      'navigation.module_pricing',
                route_name: 'settings.module.pricing',
            },
            {
                title:      'navigation.module_payments',
                route_name: 'settings.module.payments'
            },
            {
                title:      'navigation.module_platform',
                route_name: 'settings.module.platform_settings',
            },
        ],
    },

    marketing: {
        title:         'navigation.admin_marketing',
        route_name:    'admin.marketing',
        main:          false,
        submenu_items: [
            {
                title:      'navigation.admin_partners',
                route_name: 'admin.marketing.partners',
            },
            {
                title:      'navigation.admin_campaigns',
                route_name: 'admin.marketing.campaigns',
            },
            {
                title:      'navigation.admin_affiliates',
                route_name: 'admin.marketing.affiliates',
            },
        ]
    },

    accounting: {
        title:         'navigation.admin_accounting',
        route_name:    'admin.accounting',
        main:          false,
        submenu_items: [
            {
                title:      'navigation.admin_invoices',
                route_name: 'admin.accounting.invoices',
            },
            {
                title:      'navigation.admin_direct_debits',
                route_name: 'admin.accounting.direct_debits',
            },
            {
                title:      'navigation.admin_invoice_presets',
                route_name: 'admin.accounting.invoice_presets',
            },
            {
                title:      'navigation.admin_ledger_accounts',
                route_name: 'admin.accounting.ledger_accounts',
            },
        ]
    },

    admin: {
        title:         'navigation.admin',
        route_name:    'admin',
        main:          false,
        submenu_items: [
            {
                title:      'navigation.admin_companies',
                route_name: 'admin.companies',
            },
            {
                title:      'navigation.admin_orders',
                route_name: 'admin.orders',
            },
            {
                title:      'navigation.admin_giftcards',
                route_name: 'admin.giftcards',
            },
            {
                title:      'navigation.admin_transactions',
                route_name: 'admin.transactions',
            },
            {
                title:      'navigation.admin_users',
                route_name: 'admin.users',
            },
            {
                title:      'navigation.admin_inventory',
                route_name: 'admin.inventory_management',
            },
            {
                title:      'navigation.admin_emails',
                route_name: 'admin.emails',
            },
            {
                title:      'navigation.admin_fraud_filters',
                route_name: 'admin.fraud.filters',
            },
        ],
    },

    company: {
        title:         'navigation.company',
        route_name:    'settings.company',
        main:          true,
        submenu_items: [
            {
                title:      'navigation.company_details',
                route_name: 'settings.company.details',
            },
            {
                title:      'navigation.company_locations',
                route_name: 'settings.company.locations',
            },
            {
                title:      'navigation.company_employees',
                route_name: 'settings.employees',
            },
            {
                title:      'navigation.company_collaborations',
                route_name: 'settings.company.collaborations',
            },
            {
                title:      'navigation.company_developers',
                route_name: 'settings.company.developers',
            }
        ],
    },

    finance: {
        title:                             'navigation.finance',
        route_name:                        'settings.finance',
        main:                              true,
        hide_from_collaborative_companies: true,
        submenu_items:                     [
            {
                title:      'navigation.finance_invoices',
                route_name: 'settings.finance.invoices',
            },
            {
                title:      'navigation.finance_payment_settings',
                route_name: 'settings.finance.payment-settings',
            },
            {
                title:      'navigation.finance_membership',
                route_name: 'settings.finance.membership',
            },
            {
                title:      'navigation.finance_collaborationsettle',
                route_name: 'settings.finance.collaborationsettle'
            },
        ],
    },

    account: {
        title:      'navigation.my_account',
        route_name: 'settings.account',
        main:       false,
    },
}
