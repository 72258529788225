// api calls
import {promotionalOrdersApi as api} from '../../api/PromotionalOrders'

// state -> info about way of storing data; https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
const state = {
    objects:     {},
    objectList:  [],
    reportingTags: [],
    objectPrice: null
}

// getters
const getters = {
    all:      state => state.objectList.map(id => state.objects[id]),
    getById:  (state) => (id) => {
        return state.objects[id]
    },
    getPrice: state => state.objectPrice,
    getReportingTags: state => state.reportingTags,
}

// actions
const actions = {
    loadAll ({commit}) {
        return new Promise((resolve, reject) => {
            api.getAll()
                .then(({data}) => {
                    commit('set', {
                        objects:     data.data,
                        objectPrice: data.price,
                        reportingTags: data.reporting_tags,
                    })
                    resolve()
                })
        })
    },
    loadById ({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.getById(payload.order_id)
                .then(({data}) => {
                    commit('update', {
                        object: data.data,
                    })
                    resolve()
                })
        })
    },
    add ({commit}, payload) {
        // The server post should be handled by g-form
        commit('add', payload)
    },
    update ({commit}, payload) {
        // The server patch should be handled by g-form
        commit('update', payload)
    },
}

// mutations
const mutations = {
    set (state, payload) {
        state.objects     = payload.objects.reduce((obj, object) => {
            obj[object.id] = object

            return obj
        }, {})
        state.objectList  = payload.objects.map(object => object.id)
        state.objectPrice = payload.objectPrice
        state.reportingTags = payload.reportingTags
    },
    add (state, payload) {
        state.objects[payload.object.id] = payload.object
        state.objectList.unshift(payload.object.id)
    },
    update (state, payload) {
        state.objects[payload.object.id] = payload.object
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
